<template>
  <div class="container-fluid pt-3">
    <Modal @close="toggleDeleteModal" :modalActive="delteActiveModal">
      <template v-slot:header>{{ t("job.delete") }}</template>
      <template v-slot:body>
        <p>{{ t("job.delete_confirm_inquire") }}</p>
      </template>
      <template v-slot:footer>
        <button @click="toggleDeleteModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
        <button @click="deleteJobGig" type="button" class="btn btn-danger">{{ t("general.delete") }}</button>
      </template>
    </Modal>
    <!-- <pre>{{ jobs }}</pre> -->
    <Modal @close="addModal = !addModal" :modalActive="addModal" modalCssClass="modal-fullscreen">
      <template v-slot:header>{{ t("job.add") }}</template>
      <template v-slot:body>
        <h3 class="mb-3">{{ t("job.add") }}</h3>
        <div class="mb-3">
          <label for="gig_date_new" class="form-label">{{ t("general.date") }}</label>
          <input v-model="newGig.gig_date" type="date" class="form-control" id="gig_date_new" />
        </div>
        <div class="mb-3">
          <label for="gig_city_new" class="form-label">{{ t("general.city") }}</label>
          <input v-model="newGig.city" type="text" class="form-control" id="gig_city_new" />
        </div>
        <div class="mb-3">
          <label for="gig_country_new" class="form-label">{{ t("general.country") }}</label>
          <select
            class="form-select"
            id="gig_country_new"
            :aria-label="t('general.country')"
            v-model="newGig.country_id"
          >
            <option v-for="country in countries" :key="'gig_counry_new_' + country.id" :value="country.id">
              {{ country.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="gig_status_new" class="form-label">{{ t("general.status") }}</label>
          <select class="form-select" id="gig_status_new" :aria-label="t('general.status')" v-model="newGig.status">
            <option v-for="status in statuses" :key="'gig_status_new_' + status.id" :value="status.id">
              {{ status.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="gig_comment_new" class="form-label">{{ t("general.comment") }}</label>
          <textarea v-model="newGig.comment" class="form-control" id="gig_comment_new" />
        </div>
        <div class="mb-3">
          <button @click="addJobNewGig" type="button" class="btn btn-success">{{ t("general.save") }}</button>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="addModal = !addModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
      </template>
    </Modal>
    <AddressesLinkSearch
      @close="searchActiveOrganizer = !searchActiveOrganizer"
      :searchActive="searchActiveOrganizer"
      :selectedCategoryId="selectedCategories"
      :modalTitle="t('general.link_organizer')"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateOrganizer(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <AddressesLinkSearch
      @close="searchActiveArtist = !searchActiveArtist"
      :searchActive="searchActiveArtist"
      :selectedCategoryId="selectedCategories"
      :modalTitle="t('general.link_artist')"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateArtist(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <AddressesLinkSearch
      @close="searchActiveVenue = !searchActiveVenue"
      :searchActive="searchActiveVenue"
      :selectedCategoryId="selectedCategories"
      :modalTitle="t('general.link_venue')"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateVenue(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <AddressesLinkSearch
      @close="searchActiveFestival = !searchActiveFestival"
      :searchActive="searchActiveFestival"
      :selectedCategoryId="selectedCategories"
      :modalTitle="t('general.link_festival')"
    >
      <template #linkaction="linkaction">
        <div class="card-header d-flex justify-content-center">
          <button
            @click="updateFestival(linkaction.address.id)"
            type="button"
            class="btn btn-success btn-icon me-2"
            :title="t('general.link')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
            </svg>
            {{ t("general.link") }}
          </button>
        </div>
      </template>
    </AddressesLinkSearch>
    <SelectStageModal
      @close="searchActiveStage = !searchActiveStage"
      @setStage="updateStage"
      :searchActive="searchActiveStage"
      :venueId="currentVenueId"
    ></SelectStageModal>
    <div @input="userInput()" @click="scrollLock = true" class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("general.jobs") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ displayName }}</div>
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <div class="gws_text_small">
        <!-- add new -->
        <div class="gws_control_bar d-lg-flex justify-content-center align-items-center p-2 mt-3 mb-2">
          <button
            v-if="address.is_artist || address.is_organizer"
            @click="addModal = !addModal"
            type="button"
            class="d-flex flex-row align-items-center justify-content-center btn btn-success p-0 pe-2"
            title="Erstellen"
          >
            <svg class="" width="32" height="32" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
            </svg>
            {{ t("job.add") }}
          </button>
          <!-- <pre>{{ address }}</pre> -->
          <!-- <pre>{{ gigs.length }}</pre> -->
        </div>
        <!-- availabilities -->
        <div v-if="address.is_artist" class="gws_control_bar p-2 mt-3 mb-2">
          <details>
            <summary class="gws_summary">{{ t("general.availablilities") }}</summary>
            <div class="gws_card-group gws_card-group-gap mt-4">
              <div
                v-for="availability in address.availabilities"
                :key="availability.id"
                :class="'card ' + getAvailabilityClasses(availability)"
              >
                <div class="card-header">{{ t("availability." + availability.type) }}</div>
                <div class="card-body">
                  <h3 class="card-title">{{ localDate(availability.from) }} - {{ localDate(availability.to) }}</h3>
                  <p class="card-text" v-html="nl2br(availability.comment)"></p>
                </div>
              </div>
            </div>
            <!-- <pre>{{ address.availabilities }}</pre> -->
          </details>
        </div>
        <!-- jobs -->
        <div class="row tbl-brdb">
          <div class="fw-bold col-sm col-lg-2 col-xxl-1">
            <!-- title status -->
            <div @click="sortJoblist('status')" class="gws_cp">
              {{ t("general.status") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('status')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('status')"></use>
              </svg>
            </div>
          </div>
          <!-- title date -->
          <div class="fw-bold col-sm col-lg-2 col-xxl-1">
            <div @click="sortJoblist('date')" class="gws_cp">
              {{ t("general.date") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('date')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('date')"></use>
              </svg>
            </div>
          </div>
          <!-- title country -->
          <!-- <div class="fw-bold col-lg">
            <div @click="sortJoblist('country')" class="gws_cp">
              {{ t("general.country") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('country')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('country')"></use>
              </svg>
            </div>
          </div> -->
          <!-- title country, city -->
          <div class="fw-bold col-sm col-lg">
            <div @click="sortJoblist('country')" class="gws_cp d-inline-block me-2">
              {{ t("general.country") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('country')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('country')"></use>
              </svg>
            </div>
            <div @click="sortJoblist('city')" class="gws_cp d-inline-block">
              {{ t("general.city") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('city')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('city')"></use>
              </svg>
            </div>
          </div>
          <!-- title organizer -->
          <div v-if="address.is_artist" class="fw-bold col-sm col-lg">
            <div @click="sortJoblist('organizer')" class="gws_cp">
              {{ t("general.organizer") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('organizer')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('organizer')"></use>
              </svg>
            </div>
          </div>
          <!-- title artist -->
          <div v-if="address.is_organizer" class="fw-bold col-sm col-lg">
            <div @click="sortJoblist('artist')" class="gws_cp">
              {{ t("general.artist") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('artist')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('artist')"></use>
              </svg>
            </div>
          </div>
          <!-- title venue -->
          <div class="fw-bold col-lg d-none d-xxl-block">
            <div @click="sortJoblist('venue')" class="gws_cp">
              {{ t("general.venue") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('venue')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('venue')"></use>
              </svg>
            </div>
          </div>
          <!-- title festival -->
          <div class="fw-bold col-lg d-none d-xxl-block">
            <div @click="sortJoblist('festival')" class="gws_cp">
              {{ t("general.festival") }}
              <svg
                :class="'bi ms-2' + joblistIconColor('festival')"
                style="margin-bottom: 4px"
                width="18"
                height="18"
                fill="currentColor"
              >
                <use :xlink:href="'/assets/img/bootstrap-icons.svg#' + joblistIcon('festival')"></use>
              </svg>
            </div>
          </div>
          <!-- title sum -->
          <div class="fw-bold col-lg d-none d-lg-block">
            Gage<span class="d-none d-lg-inline d-xxl-none">, Venue, Festival</span>
          </div>
          <!-- title edit -->
          <div class="fw-bold col-lg-2 col-xxl-1 d-none d-lg-block text-end">{{ t("general.edit") }}</div>
          <!-- <pre>{{ subnav }}</pre> -->
          <!-- <pre>{{ statuses }}</pre> -->
        </div>
        <!-- list jobs -->
        <template v-for="job in jobs" :key="'condensed_list_' + job.id">
          <!-- title="bearbeiten: Doppelklick" -->
          <div
            :class="getJobSliceClasses(job, address)"
            :id="'jobid_' + job.id"
            @dblclick="
              job.edit = !job.edit;
              scrollLock = true;
            "
          >
            <!-- status -->
            <div class="col-lg-2 col-xxl-1">
              <span class="d-inline d-lg-none me-2">{{ t("general.status") }}:</span>
              <span class="gws_status_marker" :style="currentStatusColors(job.gig.status)"></span
              >{{ getStatusName(job.gig.status) }}
            </div>
            <!-- date -->
            <div class="col-lg-2 col-xxl-1">
              <span class="d-inline d-lg-none me-2">{{ t("general.date") }}:</span>
              {{ localDate(job.gig.gig_date) }}
            </div>
            <!-- <div class="col-lg">
              {{ getCountryCode(job.gig.country_id) }}
            </div> -->
            <!-- country and city -->
            <div class="col-lg">
              <span class="d-inline d-lg-none me-2">{{ t("general.country") }}, {{ t("general.city") }}:</span>
              <span
                class="badge rounded-pill bg-secondary me-1 mb-1 gws_cp"
                :title="getCountryName(job.gig.country_id)"
                >{{ getCountryCode(job.gig.country_id) }}</span
              >
              {{ job.gig.city }}
            </div>
            <!--  organizer-->
            <div v-if="address.is_artist" class="col-lg text-break">
              <span class="d-inline d-lg-none me-2">{{ t("general.organizer") }}:</span>
              <router-link v-if="job.organizer" :to="'/addresses/edit/' + job.organizer.id" class="text-reset"
                >{{ getDisplayName(job.organizer) }}
              </router-link>
            </div>
            <!-- artist -->
            <div v-if="address.is_organizer" class="col-lg text-break">
              <span class="d-inline d-lg-none me-2">{{ t("general.artist") }}:</span>
              <router-link v-if="job.artist" :to="'/addresses/edit/' + job.artist.id" class="text-reset"
                >{{ getDisplayName(job.artist) }}
              </router-link>
            </div>
            <!-- venue -->
            <div class="col-lg d-none d-xxl-block">
              <router-link
                v-if="job.gig && job.gig.venue"
                :to="'/addresses/edit/' + job.gig.venue.id"
                class="text-reset"
                >{{ getDisplayName(job.gig.venue) }}
              </router-link>
            </div>
            <!-- festival -->
            <div class="col-lg d-none d-xxl-block">
              <router-link
                v-if="job.gig && job.gig.festival"
                :to="'/addresses/edit/' + job.gig.festival.id"
                class="text-reset"
                >{{ getDisplayName(job.gig.festival) }}
              </router-link>
            </div>
            <!-- fee and more -->
            <div class="col-lg">
              <span class="d-inline d-lg-none me-2">{{ t("job.fee") }}:</span>
              {{ summNetto(job) }} {{ job.currency }}
              <div v-if="job.gig && job.gig.venue" class="d-block d-xxl-none">
                <span class="d-inline d-lg-none me-2">{{ t("general.venue") }}:</span>
                {{ getDisplayName(job.gig.venue) }}
              </div>
              <div v-if="job.gig && job.gig.festival" class="d-block d-xxl-none">
                <span class="d-inline d-lg-none me-2">{{ t("general.festival") }}:</span>
                {{ getDisplayName(job.gig.festival) }}
              </div>
            </div>
            <div class="col-lg-2 col-xxl-1 text-end d-flex align-items-center justify-content-end">
              <button
                @click="
                  job.edit = !job.edit;
                  scrollLock = true;
                "
                class="btn btn-success btn-icon-sm"
                :title="t('general.edit')"
              >
                <svg class="bi" width="18" height="18" fill="currentColor">
                  <use
                    :xlink:href="
                      job.edit
                        ? '/assets/img/bootstrap-icons.svg#chevron-up'
                        : '/assets/img/bootstrap-icons.svg#chevron-down'
                    "
                  ></use>
                </svg>
                {{ t("general.edit") }}
              </button>
            </div>
          </div>
          <!-- job details -->
          <div v-if="job.edit" class="row mb-4 gws_slice gws_slice_bottom" :id="'job_edit_id_' + job.id">
            <div class="col-12">
              <!-- ##### -->
              <!-- <pre>{{ job.gig }}</pre> -->
              <!-- <pre>{{ job.gig.id }}</pre> -->
              <!-- <pre>{{ subnav }}</pre> -->
              <!-- <pre>{{ job }}</pre> -->
              <!-- <pre>{{ gigs.length }}</pre> -->
              <!-- <pre>{{ getGigFromJob(job.gig.id) }}</pre> -->
              <!-- <pre>{{ gigs }}</pre> -->
              <!-- yyyy -->
              <div class="card my-2">
                <!-- jobnav -->
                <div class="card-header d-md-flex align-items-end justify-content-between">
                  <ul class="nav nav-tabs card-header-tabs">
                    <template v-if="typeof subnav !== 'undefined' && subnav.length > 0">
                      <li class="nav-item" v-for="(navitem, navindex) in subnav" :key="'job_subnav_' + navindex">
                        <!-- <pre>{{ navitem.title }}</pre> -->
                        <!-- <pre>{{ job.subnavActive }}</pre> -->
                        <button
                          @click="job.subnavActive = navitem.id"
                          :class="'nav-link d-flex flex-row' + (navitem.id === job.subnavActive ? ' active' : '')"
                          :aria-current="navitem.id === job.subnavActive ? 'true' : 'false'"
                        >
                          <span>{{ navitem.title }}</span>
                          <svg
                            v-if="job[navitem.id] || job.gig[navitem.id]"
                            width="20"
                            height="20"
                            fill="#currentColor"
                            style="margin-top: 0.15rem"
                          >
                            <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                          </svg>
                        </button>
                      </li>
                    </template>
                  </ul>
                  <!-- btn-group -->
                  <div class="gws_card_button_group mt-md-0 mt-4 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-danger btn-icon me-2"
                      @click="toggleDeleteModal(job)"
                      :title="t('general.delete')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                      </svg>
                      {{ t("general.delete") }}
                    </button>
                    <button
                      @click="updateJobAndGig(job)"
                      :disabled="!userHasInput"
                      type="button"
                      class="btn btn-success btn-icon me-n2"
                      title="Speichern"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                      </svg>
                      {{ t("general.save") }}
                    </button>
                  </div>
                </div>
                <!-- job main -->
                <div class="card-body">
                  <!-- gig -->
                  <div v-if="job.subnavActive === 'base'" class="card-text">
                    <div class="row g-3">
                      <div class="col-xl-2 col-sm-4">
                        <label :for="'job_gig_date_' + job.id" class="form-label"
                          >{{ t("general.date") }} <span class="d-none">{{ job.gig.id }}</span></label
                        >
                        <input
                          v-model="job.gig.gig_date"
                          type="date"
                          class="form-control"
                          :id="'job_gig_date_' + job.id"
                        />
                      </div>
                      <div class="col-xl-2 col-sm-4">
                        <label :for="'job_gig_city_' + job.id" class="form-label">{{ t("general.city") }}</label>
                        <input v-model="job.gig.city" type="text" class="form-control" :id="'job_gig_city_' + job.id" />
                      </div>
                      <div class="col-xl-2 col-sm-4">
                        <label :for="'job_gig_country_' + job.id" class="form-label">{{ t("general.country") }}</label>
                        <select
                          class="form-select"
                          :id="'job_gig_country_' + job.id"
                          :aria-label="t('general.country')"
                          v-model="job.gig.country_id"
                        >
                          <option v-for="country in countries" :key="job.gig.id + '_' + country.id" :value="country.id">
                            {{ country.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-xl-2 col-sm-4">
                        <label :for="'job_gig_status_' + job.gig.id" class="form-label"
                          >{{ t("general.status") }}
                          <span class="status-marker" :style="currentStatusColors(job.gig.status)"></span>
                        </label>
                        <select
                          class="form-select"
                          :id="'job_gig_status_' + job.gig.id"
                          :aria-label="t('general.status')"
                          v-model="job.gig.status"
                        >
                          <option
                            v-for="status in statuses"
                            :key="job.id + '_' + status.id"
                            :value="status.id"
                            :style="getStatusStyles(status)"
                          >
                            {{ status.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-xl-4 col-sm-8">
                        <label :for="'job_gig_comment_' + job.gig.id" class="form-label">{{
                          t("general.comment")
                        }}</label>
                        <textarea
                          v-model="job.gig.comment"
                          class="form-control"
                          :id="'job_gig_comment_' + job.gig.id"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top mt-3 pt-2">
                        <!-- <button
                          @click="saveGig(job.gig, job.id)"
                          type="button"
                          class="btn btn-success btn-icon me-2"
                          :title="t('general.save')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                          </svg>
                          {{ t("general.save") }}
                        </button> -->
                        <router-link
                          :to="'/gig/' + job.gig.id"
                          class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                          :title="t('general.edit')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                          </svg>
                          {{ t("general.edit") }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- organizer -->
                  <div v-if="job.subnavActive === 'organizer' && address.is_artist" class="card-text">
                    <div v-if="typeof job.organizer !== 'undefined' && job.organizer !== null">
                      <CardText :address="job.organizer" :countries="countries">
                        <template #cardaction>
                          <router-link
                            :to="'/addresses/edit/' + job.organizer.id"
                            class="btn btn-success btn-icon mt-2 me-2 is-rows-el edit-btn"
                            :title="t('general.edit')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                            </svg>
                            {{ t("general.edit") }}
                          </router-link>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              showModal('Veranstalter', job.id);
                            "
                            type="button"
                            class="btn btn-warning btn-icon me-2 mt-2"
                            :title="t('general.change_assignment')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.change_assignment") }}
                          </button>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              updateOrganizer(null);
                            "
                            type="button"
                            class="btn btn-danger btn-icon me-2 mt-2"
                            :title="t('general.remove')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.remove") }}
                          </button>
                        </template>
                      </CardText>
                    </div>
                    <div v-else>
                      <button
                        @click="
                          currentJobId = job.id;
                          currentGigId = job.gig.id;
                          showModal('Veranstalter', job.id);
                        "
                        type="button"
                        class="btn btn-success btn-icon me-2 mt-2"
                        :title="t('general.add')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                        </svg>
                        {{ t("general.add") }}
                      </button>
                    </div>
                  </div>
                  <!-- artist -->
                  <div v-if="job.subnavActive === 'artist' && address.is_organizer" class="card-text">
                    <div v-if="typeof job.artist !== 'undefined' && job.artist !== null">
                      <CardText :address="job.artist" :countries="countries">
                        <template #cardaction>
                          <router-link
                            :to="'/addresses/edit/' + job.artist.id"
                            class="btn btn-success btn-icon me-2 is-rows-el edit-btn mt-2"
                            :title="t('general.edit')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                            </svg>
                            {{ t("general.edit") }}
                          </router-link>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              showModal('Künstler', job.id);
                            "
                            type="button"
                            class="btn btn-warning btn-icon me-2 mt-2"
                            :title="t('general.change_assignment')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.change_assignment") }}
                          </button>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              updateArtist(null);
                            "
                            type="button"
                            class="btn btn-danger btn-icon me-2 mt-2"
                            :title="t('general.remove')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.remove") }}
                          </button>
                        </template>
                      </CardText>
                    </div>
                    <div v-else>
                      <button
                        @click="
                          currentJobId = job.id;
                          currentGigId = job.gig.id;
                          showModal('Künstler', job.id);
                        "
                        type="button"
                        class="btn btn-success btn-icon me-2 mt-2"
                        :title="t('general.add')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                        </svg>
                        {{ t("general.add") }}
                      </button>
                    </div>
                  </div>
                  <!-- terms -->
                  <div v-if="job.subnavActive === 'terms'" class="card-text">
                    <h3>Vertragsbedingungen</h3>
                    <div class="mb-2 fs-7">Aufttrag ID: {{ job.id }}</div>
                    <div class="row">
                      <div class="col-xl-6 col-sm-12">
                        <!-- currency &  mwstpercent -->
                        <div class="row">
                          <div class="col-xl-6 col-sm-6">
                            <label :for="'job_terms_currency' + job.id" class="form-label">{{
                              t("general.currency")
                            }}</label>
                            <input
                              v-model="job.currency"
                              type="text"
                              class="form-control"
                              :id="'job_terms_currency' + job.id"
                            />
                          </div>
                          <div class="col-xl-6 col-sm-6">
                            <label :for="'job_terms_mwstpercent' + job.id" class="form-label">{{
                              t("general.mwstpercent")
                            }}</label>
                            <input
                              v-model="job.MWSt_rate"
                              type="text"
                              class="form-control"
                              :id="'job_terms_mwstpercent' + job.id"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-5 col-sm-5 py-3 mt-3">
                            <div><strong>Preis</strong></div>
                            <!-- fee -->
                            <label :for="'job_terms_offer_fee' + job.id" class="form-label">{{ t("job.fee") }}</label>
                            <input
                              v-model="job.offer_fee"
                              type="number"
                              step="0.01"
                              class="form-control job_terms_offer_fee mb-3"
                              :id="'job_terms_offer_fee' + job.id"
                            />
                            <!-- production_costs -->
                            <label :for="'job_terms_production_costs' + job.id" class="form-label">{{
                              t("job.production_costs")
                            }}</label>
                            <input
                              v-model="job.production_costs"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_production_costs' + job.id"
                            />
                            <!-- meal_allowance -->
                            <label :for="'job_terms_meal_allowance_' + job.id" class="form-label">{{
                              t("job.meal_allowance")
                            }}</label>
                            <input
                              v-model="job.meal_allowance"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_meal_allowance_' + job.id"
                            />
                            <!-- promomaterial -->
                            <label :for="'job_terms_promomaterial_' + job.id" class="form-label">{{
                              t("job.promomaterial")
                            }}</label>
                            <input
                              v-model="job.promomaterial"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_promomaterial_' + job.id"
                            />
                            <!-- provision -->
                            <label :for="'job_terms_provision_' + job.id" class="form-label">{{
                              t("job.provision")
                            }}</label>
                            <input
                              v-model="job.provision"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_provision_' + job.id"
                            />
                          </div>
                          <div class="col-xl-5 col-sm-5 py-3 mt-3 bg-offer-fee">
                            <div><strong>unser Preis</strong></div>
                            <!-- offered_offer_fee -->
                            <label :for="'job_terms_offered_offer_fee' + job.id" class="form-label">{{
                              t("job.fee")
                            }}</label>
                            <input
                              v-model="job.offered_offer_fee"
                              type="number"
                              step="0.01"
                              class="form-control job_terms_offer_fee mb-3"
                              :id="'job_terms_offered_offer_fee' + job.id"
                            />
                            <!-- offered_production_costs -->
                            <label :for="'job_terms_offered_production_costs' + job.id" class="form-label">{{
                              t("job.production_costs")
                            }}</label>
                            <input
                              v-model="job.offered_production_costs"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_offered_production_costs' + job.id"
                            />
                            <!-- offered_meal_allowance -->
                            <label :for="'job_terms_offered_meal_allowance_' + job.id" class="form-label">{{
                              t("job.meal_allowance")
                            }}</label>
                            <input
                              v-model="job.offered_meal_allowance"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_offered_meal_allowance_' + job.id"
                            />
                            <!-- offered_promomaterial -->
                            <label :for="'job_terms_offered_promomaterial_' + job.id" class="form-label">{{
                              t("job.meal_allowance")
                            }}</label>
                            <input
                              v-model="job.offered_promomaterial"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_offered_promomaterial_' + job.id"
                            />
                            <!-- offered_provision -->
                            <label :for="'job_terms_offered_provision_' + job.id" class="form-label">{{
                              t("job.provision")
                            }}</label>
                            <input
                              v-model="job.offered_provision"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_offered_provision_' + job.id"
                            />
                          </div>
                          <div class="col-xl-2 col-sm-2 py-3 mt-3">
                            <div>
                              <strong>{{ t("general.mwst") }}</strong>
                            </div>
                            <!-- MWSt_offer_fee -->
                            <div>
                              <input
                                type="checkbox"
                                v-model="job.MWSt_offer_fee"
                                class="form-check-input d-sm-block me-2 job_terms_MWSt_offer_fee"
                                style=""
                                :id="'job_terms_MWSt_offer_fee_' + job.id"
                              />
                              <label :for="'job_terms_MWSt_offer_fee_' + job.id" class="form-label d-sm-none">
                                {{ t("general.mwst") }}
                                {{ t("job.fee") }}
                              </label>
                            </div>
                            <!-- MWSt_production_costs -->
                            <div>
                              <input
                                type="checkbox"
                                v-model="job.MWSt_production_costs"
                                value="1"
                                class="form-check-input me-2 job_terms_MWSt"
                                :id="'job_terms_MWSt_production_costs_' + job.id"
                              />
                              <label :for="'job_terms_MWSt_production_costs_' + job.id" class="form-label d-sm-none">
                                {{ t("general.mwst") }}
                                {{ t("job.production_costs") }}
                              </label>
                            </div>
                            <!-- MWSt_meal_allowance -->
                            <div>
                              <input
                                type="checkbox"
                                v-model="job.MWSt_meal_allowance"
                                value="1"
                                class="form-check-input me-2 job_terms_MWSt"
                                :id="'job_terms_MWSt_meal_allowance_' + job.id"
                              />
                              <label :for="'job_terms_MWSt_meal_allowance_' + job.id" class="form-label d-sm-none">
                                {{ t("general.mwst") }}
                                {{ t("job.meal_allowance") }}
                              </label>
                            </div>
                            <!-- MWSt_promomaterial -->
                            <div>
                              <input
                                type="checkbox"
                                v-model="job.MWSt_promomaterial"
                                value="1"
                                class="form-check-input me-2 job_terms_MWSt"
                                :id="'job_terms_MWSt_promomaterial_' + job.id"
                              />
                              <label :for="'job_terms_MWSt_promomaterial_' + job.id" class="form-label d-sm-none">
                                {{ t("general.mwst") }}
                                {{ t("job.promomaterial") }}
                              </label>
                            </div>
                            <!-- MWSt_provision -->
                            <div>
                              <input
                                type="checkbox"
                                v-model="job.MWSt_provision"
                                value="1"
                                class="form-check-input me-2 job_terms_MWSt"
                                :id="'job_terms_MWSt_provision_' + job.id"
                              />
                              <label :for="'job_terms_MWSt_provision_' + job.id" class="form-label d-sm-none">
                                {{ t("general.mwst") }}
                                {{ t("job.provision") }}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-3 col-sm-3 py-2 mt-3">
                            Summe netto
                            <div class="bg-light-yellow">{{ summNetto(job) }} {{ job.currency }}</div>
                          </div>
                          <div class="col-xl-3 col-sm-3 py-2 mt-3">
                            MwSt-pflichtig
                            <div class="bg-light-yellow">{{ subjecToVAT(job) }} {{ job.currency }}</div>
                          </div>
                          <div class="col-xl-3 col-sm-3 py-2 mt-3">
                            MwSt.
                            <div class="bg-light-yellow">{{ vat(job) }} {{ job.currency }}</div>
                          </div>
                          <div class="col-xl-3 col-sm-3 py-2 mt-3">
                            gesamt
                            <div class="bg-light-yellow">{{ summBrutto(job) }} {{ job.currency }}</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6 mt-3">
                            <!-- terms_of_payment -->
                            <label :for="'job_terms_terms_of_payment_' + job.id" class="form-label">{{
                              t("job.terms_of_payment")
                            }}</label>
                            <input
                              v-model="job.terms_of_payment"
                              type="text"
                              class="form-control mb-3"
                              :id="'job_terms_terms_of_payment_' + job.id"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 mt-3">
                            <h3>Tickets &amp; Plätze</h3>
                          </div>
                        </div>
                        <!-- tickets -->
                        <div class="row">
                          <div class="col-sm-6">
                            <label :for="'job_terms_tickets_VVK_' + job.id" class="form-label">{{
                              t("job.tickets_VVK")
                            }}</label>
                            <input
                              v-model="job.tickets_VVK"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_tickets_VVK_' + job.id"
                            />
                            <label :for="'job_terms_tickets_AK_' + job.id" class="form-label">{{
                              t("job.tickets_AK")
                            }}</label>
                            <input
                              v-model="job.tickets_AK"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_tickets_AK_' + job.id"
                            />
                            <label :for="'job_terms_tickets_reduced_' + job.id" class="form-label">{{
                              t("job.tickets_reduced")
                            }}</label>
                            <input
                              v-model="job.tickets_reduced"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_tickets_reduced_' + job.id"
                            />
                            <div>
                              Tickets Durchschnitt
                              <div class="bg-light-yellow my-eq-form">{{ ticketsAverage(job) }} {{ job.currency }}</div>
                            </div>
                          </div>
                          <!-- seats -->
                          <div class="col-sm-6">
                            <label :for="'job_terms_capacity_seated_' + job.id" class="form-label">{{
                              t("job.capacity_seated")
                            }}</label>
                            <input
                              v-model="job.capacity_seated"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_capacity_seated_' + job.id"
                            />
                            <label :for="'job_terms_capacity_standing_' + job.id" class="form-label">{{
                              t("job.capacity_standing")
                            }}</label>
                            <input
                              v-model="job.capacity_standing"
                              type="number"
                              step="0.01"
                              class="form-control mb-3"
                              :id="'job_terms_capacity_standing_' + job.id"
                            />
                            <div>
                              {{ t("job.gross_potential_seated") }}
                              <div class="bg-light-yellow my-eq-form">
                                {{ seatsPotentialSeated(job) }} {{ job.currency }}
                              </div>
                              {{ t("job.gross_potential_seated") }}
                              <div class="bg-light-yellow my-eq-form">
                                {{ seatsPotentialStanding(job) }} {{ job.currency }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- percentage -->
                        <div class="row">
                          <div class="col-sm-12 mt-3"><h3>Prozente auf Tickets</h3></div>
                        </div>
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_percent1_' + job.id" class="form-label">
                              {{ t("job.tickets_percents") }} 1
                            </label>
                            <input
                              v-model="job.percent1"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_percent1_' + job.id"
                            />
                          </div>
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE1_tickets_' + job.id" class="form-label">
                              {{ t("job.tickets") }}
                            </label>
                            <input
                              v-model="job.BE1_tickets"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_BE1_tickets_' + job.id"
                            />
                          </div>
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE1_betrag_' + job.id" class="form-label">
                              {{ t("job.tickets_amount") }}
                            </label>
                            <input
                              v-model="job.BE1_betrag"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_BE1_betrag_' + job.id"
                            />
                          </div>
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE1_MWSt_' + job.id" class="form-label">
                              {{ t("general.mwst") }}
                            </label>
                            <input
                              v-model="job.BE1_MWSt"
                              type="checkbox"
                              class="form-check-input d-sm-block me-2 mb-3"
                              :id="'job_terms_BE1_MWSt_' + job.id"
                            />
                          </div>
                        </div>
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_percent2_' + job.id" class="form-label">
                              {{ t("job.tickets_percents") }} 2
                            </label>
                            <input
                              v-model="job.percent2"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_percent2_' + job.id"
                            />
                          </div>
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE2_tickets_' + job.id" class="form-label">
                              {{ t("job.tickets") }}
                            </label>
                            <input
                              v-model="job.BE2_tickets"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_BE2_tickets_' + job.id"
                            />
                          </div>
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE2_betrag_' + job.id" class="form-label">
                              {{ t("job.tickets_amount") }}
                            </label>
                            <input
                              v-model="job.BE2_betrag"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_BE2_betrag_' + job.id"
                            />
                          </div>
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE2_MWSt_' + job.id" class="form-label">
                              {{ t("general.mwst") }}
                            </label>
                            <input
                              v-model="job.BE2_MWSt"
                              type="checkbox"
                              class="form-check-input d-sm-block me-2 mb-3"
                              :id="'job_terms_BE2_MWSt_' + job.id"
                            />
                          </div>
                        </div>
                        <div class="row g-3">
                          <div class="col-md-3">
                            <label :for="'job_terms_tickets_BE_total_' + job.id" class="form-label">
                              {{ t("job.tickets_breakeven") }}
                            </label>
                            <input
                              v-model="job.BE_total"
                              type="number"
                              class="form-control mb-3"
                              :id="'job_terms_BE_total_' + job.id"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-sm-12">
                        <!-- main deal -->
                        <label :for="'job_terms_main_deal' + job.id" class="form-label">{{ t("job.main_deal") }}</label>
                        <textarea
                          v-model="job.main_deal"
                          rows="15"
                          type="text"
                          class="form-control mb-3"
                          :id="'job_terms_main_deal' + job.id"
                        />
                        <!-- comment -->
                        <label :for="'job_terms_comment' + job.id" class="form-label">{{
                          t("job.deal_with_organizer")
                        }}</label>
                        <textarea
                          v-model="job.comment"
                          rows="15"
                          type="text"
                          class="form-control mb-3"
                          :id="'job_terms_comment' + job.id"
                        />
                        <!-- special_agreements -->
                        <label :for="'job_terms_special_agreements_' + job.id" class="form-label">{{
                          t("job.special_agreements")
                        }}</label>
                        <textarea
                          v-model="job.special_agreements"
                          rows="15"
                          type="text"
                          class="form-control mb-3"
                          :id="'job_terms_special_agreements_' + job.id"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 border-top mt-3 pt-2">
                        <!-- hier können Sie die Auftragsdetails eingeben -->
                        <!-- <button
                          @click="save(job)"
                          type="button"
                          class="btn btn-success btn-icon me-2 mt-2"
                          :title="t('general.save')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                          </svg>
                          {{ t("general.save") }}
                        </button> -->
                        <!-- <router-link
                          :to="'/job/' + job.id"
                          class="btn btn-success btn-icon me-2 is-rows-el edit-btn mt-2"
                          :title="t('general.edit')"
                        >
                          <svg class="bi" width="20" height="20" fill="currentColor">
                            <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                          </svg>
                          {{ t("general.edit") }}
                        </router-link> -->
                      </div>
                    </div>
                    <!-- <pre>{{ address }}</pre> -->
                    <!-- <pre>{{ job }}</pre> -->
                  </div>

                  <!-- venue -->
                  <div v-if="job.subnavActive === 'venue'" class="row">
                    <!-- <pre>{{ job }}</pre> -->
                    <div class="col" v-if="job?.gig?.venue">
                      <CardText :address="job.gig.venue" :countries="countries">
                        <template #cardaction>
                          <router-link
                            :to="'/addresses/edit/' + job.gig.venue.id"
                            class="btn btn-success btn-icon me-2 is-rows-el edit-btn mt-2"
                            :title="t('general.edit')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                            </svg>
                            {{ t("general.edit") }}
                          </router-link>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              showModal('Venue', job.id);
                            "
                            type="button"
                            class="btn btn-warning btn-icon me-2 mt-2"
                            :title="t('general.change_assignment')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.change_assignment") }}
                          </button>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              updateVenue(null);
                            "
                            type="button"
                            class="btn btn-danger btn-icon me-2 mt-2"
                            :title="t('general.remove')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.remove") }}
                          </button>
                        </template>
                      </CardText>
                    </div>
                    <div class="col" v-else>
                      <button
                        @click="
                          currentJobId = job.id;
                          currentGigId = job.gig.id;
                          showModal('Venue', job.id);
                        "
                        type="button"
                        class="btn btn-success btn-icon me-2 mt-2"
                        :title="t('general.add')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                        </svg>
                        {{ t("general.add") }}
                      </button>
                    </div>
                    <div class="col-md-4 col-lg-3 mt-3 mt-md-0 pb-3 border rounded" v-if="job?.gig?.stage">
                      <!-- TODO: stage -->
                      <StageCardText :stage="job.gig.stage">
                        <template #cardaction>
                          <router-link
                            v-if="job.gig.stage.id"
                            :to="'/addresses/stages/' + job.gig.venue.id"
                            class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                            :title="t('general.edit')"
                            target="_blank"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                            </svg>
                            {{ t("general.edit") }}
                          </router-link>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              currentVenueId = job.gig.venue.id;
                              searchActiveStage = !searchActiveStage;
                            "
                            class="btn btn-warning btn-icon me-2 edit-btn"
                            :title="t('general.change_assignment')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.change_assignment") }}
                          </button>
                          <button
                            class="btn btn-danger btn-icon me-2 is-rows-el edit-btn"
                            :title="t('general.remove')"
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              job.gig.stage_id = null;
                              updateStage(null);
                            "
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                            </svg>
                            {{ t("general.remove") }}
                          </button>
                        </template>
                      </StageCardText>
                    </div>
                    <div class="col-md-4 col-lg-3 mt-3 mt-md-0 pb-3 border rounded" v-else-if="job?.gig?.venue">
                      <h2 class="mt-4">{{ t("general.stage") }}</h2>
                      <button
                        @click="
                          currentJobId = job.id;
                          currentGigId = job.gig.id;
                          currentVenueId = job.gig.venue.id;
                          searchActiveStage = !searchActiveStage;
                        "
                        type="button"
                        class="btn btn-success btn-icon me-2 mt-2"
                        :title="t('general.link')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                        </svg>
                        {{ t("general.link") }}
                      </button>
                    </div>
                  </div>
                  <!-- festival -->
                  <template v-if="job.subnavActive === 'festival'">
                    <template v-if="job.gig.festival !== null">
                      <CardText :address="job.gig.festival" :countries="countries">
                        <template #cardaction>
                          <router-link
                            :to="'/addresses/edit/' + job.gig.festival.id"
                            class="btn btn-success btn-icon me-2 is-rows-el edit-btn mt-2 mt-2"
                            :title="t('general.edit')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                            </svg>
                            {{ t("general.edit") }}
                          </router-link>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              showModal('Festival', job.id);
                            "
                            type="button"
                            class="btn btn-warning btn-icon me-2 mt-2 mt-2"
                            :title="t('general.change_assignment')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.change_assignment") }}
                          </button>
                          <button
                            @click="
                              currentJobId = job.id;
                              currentGigId = job.gig.id;
                              updateFestival(null);
                            "
                            type="button"
                            class="btn btn-danger btn-icon me-2 mt-2"
                            :title="t('general.remove')"
                          >
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                            </svg>
                            {{ t("general.remove") }}
                          </button>
                        </template>
                      </CardText>
                    </template>
                    <template v-else>
                      <button
                        @click="
                          currentJobId = job.id;
                          currentGigId = job.gig.id;
                          showModal('Festival', job.id);
                        "
                        type="button"
                        class="btn btn-success btn-icon me-2 mt-2"
                        :title="t('general.add')"
                      >
                        <svg class="bi" width="20" height="20" fill="currentColor">
                          <use xlink:href="/assets/img/bootstrap-icons.svg#link"></use>
                        </svg>
                        {{ t("general.add") }}
                      </button>
                    </template>
                  </template>
                  <!-- end: festival -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- <div v-if="jobs.length > showJobsNumber" class="row my-2">
          <div class="col d-flex align-items-center justify-content-center">
            <button @click="loadMoreData" class="btn btn-primary btn-icon">
              <svg width="20" height="20" class="bi me-1" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#plus-circle-fill"></use>
              </svg>
              {{ t("general.load_more_data") }}
            </button>
          </div>
        </div> -->
      </div>
      <!-- <pre>{{ jobs }}</pre> -->
    </div>
  </div>
</template>

<script>
import { ref, computed, onUpdated } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import { getContrastColor, nl2br } from "@/composables/utilities.js";
import axios from "axios";
import storeConfig from "../../store/config";
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal.vue";
import AddressNavigation from "@/components/navigation/Address.vue";
import CardText from "@/components/addresses/CardText.vue";
import StageCardText from "@/components/stages/CardText.vue";
import AddressesLinkSearch from "@/components/addresses/AddressesLinkSearch.vue";
import SelectStageModal from "../../components/stages/SelectStageModal.vue";
export default {
  name: "jobs",
  components: {
    Modal,
    AddressNavigation,
    CardText,
    StageCardText,
    AddressesLinkSearch,
    SelectStageModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();
    const currentUser = computed(() => store.getters.user);
    const subnav = ref([
      // { id: "base", title: t("general.gig") },
      // { id: "terms", title: t("general.terms_of_contract") },
      // { id: "venue", title: t("general.venue") },
      // { id: "festival", title: t("general.festival") },
    ]);
    // const lastEdited = ref({ id: null, area: null });
    const scrollLock = ref(false);

    function setSubnav() {
      // { id: "dealsheet", title: t("general.dealsheet_control") },
      subnav.value = [
        { id: "base", title: t("general.gig") },
        { id: "terms", title: t("general.terms_of_contract") },
      ];
      if (address.value.is_artist === true) {
        subnav.value.push({ id: "organizer", title: t("general.organizer") });
      }
      if (address.value.is_organizer === true) {
        subnav.value.push({ id: "artist", title: t("general.artist") });
      }
      subnav.value.push({ id: "venue", title: t("general.venue") });
      subnav.value.push({ id: "festival", title: t("general.festival") });
    }

    const activeTab = ref({
      id: null,
      tab: "base",
    });
    const { result: address, query: getAddress } = useApiQuery({
      url: "addresses/" + route.params.id,
      defaultVal: [],
      params: {
        params: {
          // id: route.params.id,
          withAvailabilities: true,
        },
      },
      pfn: function (data) {
        data.is_artist = false;
        data.is_organizer = false;
        for (const category of data.categories) {
          if (category["Künstler"] === 1) {
            data.is_artist = true;
            break;
          }
          if (category["Veranstalter"] === 1) {
            data.is_organizer = true;
            break;
          }
        }
        return data;
      },
    });
    const currentJobId = ref(null);
    const currentGigId = ref(null);
    const currentVenueId = ref(null);
    const { result: jobs, query: getJobs } = useApiQuery({
      // url: "search/job/byartist",
      defaultVal: [],
      params: {
        params: {
          id: route.params.id,
        },
      },
      pfn: function (data) {
        // console.log(data);
        // return data;
        // const bar = data.map((job) => {
        return data.map((job) => {
          if (job.MWSt_offer_fee === -1 || job.MWSt_offer_fee === 1) {
            job.MWSt_offer_fee = true;
          } else {
            job.MWSt_offer_fee = false;
          }
          if (job.MWSt_production_costs === -1 || job.MWSt_production_costs === 1) {
            job.MWSt_production_costs = true;
          } else {
            job.MWSt_production_costs = false;
          }
          if (job.MWSt_meal_allowance === -1 || job.MWSt_meal_allowance === 1) {
            job.MWSt_meal_allowance = true;
          } else {
            job.MWSt_meal_allowance = false;
          }
          if (job.MWSt_promomaterial === -1 || job.MWSt_promomaterial === 1) {
            job.MWSt_promomaterial = true;
          } else {
            job.MWSt_promomaterial = false;
          }
          if (job.MWSt_provision === -1 || job.MWSt_provision === 1) {
            job.MWSt_provision = true;
          } else {
            job.MWSt_provision = false;
          }
          if (job.BE1_MWSt === -1 || job.BE1_MWSt === 1) {
            job.BE1_MWSt = true;
          } else {
            job.BE1_MWSt = false;
          }
          if (job.BE2_MWSt === -1 || job.BE2_MWSt === 1) {
            job.BE2_MWSt = true;
          } else {
            job.BE2_MWSt = false;
          }
          if (job.gig?.gig_date) {
            job.gig.gig_date = job.gig.gig_date.substr(0, 10);
          }
          if (activeTab.value.id === job.id) {
            job.subnavActive = activeTab.value.tab;
            job.edit = true;
          } else {
            job.subnavActive = "base";
            job.edit = route.params.jobid == job.id ? true : false;
          }

          // if (job.gig) {
          //   return job;
          // } else {
          //   console.log(job);
          // }

          return job;
        });
        // bar.sort((a, b) => {
        //   if (a.gig.gig_date > b.gig.gig_date) {
        //     return 1;
        //   }
        //   if (a.gig.gig_date < b.gig.gig_date) {
        //     return -1;
        //   }
        //   return 0;
        // });
        // .sort((a, b) => (a.gig.gig_date > b.gig.gig_date ? -1 : b.gig.gig_date > a.gig.gig_date ? 1 : 0))
        // console.log("bar", bar);
        // return bar;
      },
    });

    const showJobsNumber = ref(25);
    function loadMoreData() {
      showJobsNumber.value += 25;
    }
    const { result: countries, query: getCountries } = useApiQuery({ url: "country", defaultVal: [] });
    const { result: statuses, query: getStatuses } = useApiQuery({
      url: "status",
      defaultVal: [],
      pfn: function (data) {
        return data.map((status) => {
          status.textcolor = getContrastColor(status.color);
          return status;
        });
      },
    });
    const {
      result: categories,
      query: getCategories,
      error: categoriesError,
    } = useApiQuery({ url: "category", defaultVal: [] });

    // const { result: tours, query: getTours } = useApiQuery({
    //   url: "search/artisttour/byartist",
    //   defaultVal: [],
    //   params: {
    //     params: {
    //       artist_id: route.params.id,
    //     },
    //   },
    // });

    async function loadCategories() {
      await getCategories();
      if (categoriesError) {
        if (categoriesError.response && categoriesError.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      }
    }

    const selectedCategories = ref("");
    function setSelectedCategories(type) {
      selectedCategories.value = categories.value
        .filter((category) => category[type] == 1)
        .map((category) => category.id)
        .join(",");
      // console.log(selectedCategories.value);
    }

    function setCityAndCountryForNewGig() {
      if (address.value.is_organizer) {
        newGig.value.city = address.value.city;
        newGig.value.country_id = address.value.country_id;
      }
    }

    async function getAll() {
      await getAddress();
      const url = address.value.is_organizer ? "search/job/byorganizer" : "search/job/byartist";
      setCityAndCountryForNewGig();
      await Promise.all([
        setSubnav(),
        getJobs({ url: url }),
        // getGigs(gigParams),
        getCountries(),
        getStatuses(),
        loadCategories(),
        // getTours(),
      ]);
      // userHasInput.value = false;
      sortJoblist(jobSorter.value.active, true);
    }

    async function getChangedJobs() {
      const url = address.value.is_organizer ? "search/job/byorganizer" : "search/job/byartist";
      await getJobs({ url: url });
      sortJoblist(jobSorter.value.active, true);
      // userHasInput.value = false;
    }

    async function save(job) {
      activeTab.value.id = job.id;
      activeTab.value.tab = "terms";
      // console.log(job.gig.status);
      const data = {
        status: job.gig.status,
        capacity_seated: job.capacity_seated,
        capacity_standing: job.capacity_standing,
        offer_fee: job.offer_fee,
        production_costs: job.production_costs,
        meal_allowance: job.meal_allowance,
        promomaterial: job.promomaterial,
        provision: job.provision,
        offered_offer_fee: job.offered_offer_fee,
        offered_production_costs: job.offered_production_costs,
        offered_meal_allowance: job.offered_meal_allowance,
        offered_promomaterial: job.offered_promomaterial,
        offered_provision: job.offered_provision,
        MWSt_offer_fee: job.MWSt_offer_fee === true ? 1 : 0,
        MWSt_production_costs: job.MWSt_production_costs === true ? 1 : 0,
        MWSt_meal_allowance: job.MWSt_meal_allowance === true ? 1 : 0,
        MWSt_promomaterial: job.MWSt_promomaterial === true ? 1 : 0,
        MWSt_provision: job.MWSt_provision === true ? 1 : 0,
        MWSt_rate: job.MWSt_rate,
        currency: job.currency,
        terms_of_payment: job.terms_of_payment,
        tickets_VVK: job.tickets_VVK,
        tickets_AK: job.tickets_AK,
        tickets_reduced: job.tickets_reduced,
        percent1: job.percent1,
        BE1_tickets: job.BE1_tickets,
        BE1_betrag: job.BE1_betrag,
        BE1_MWSt: job.BE1_MWSt === true ? 1 : 0,
        percent2: job.percent2,
        BE2_tickets: job.BE2_tickets,
        BE2_betrag: job.BE2_betrag,
        BE2_MWSt: job.BE2_MWSt === true ? 1 : 0,
        BE_total: job.BE_total,
        special_agreements: job.special_agreements,
        main_deal: job.main_deal,
        comment: job.comment,
        artistagent: job.artistagent,
        foreigner_tax: job.foreigner_tax,
        foreigne: job.foreigne,
        AL_duty: job.AL_duty,
        updated_by: currentUser.value.id,
      };
      // const gigData = {
      //   status: job.status,
      // };
      try {
        await store.dispatch("addLoading", 1);
        await axios.put(storeConfig.apiBaseUrl + "job/" + job.id, data);
        // await axios.put(storeConfig.apiBaseUrl + "gig/" + job.gig_id, gigData);
        toast.success(t("general.job") + " " + t("general.successfully") + " " + t("general.edited") + ".");
        // scrollLock.value = false;
        // if (!willUpdateJobAndGig.value) {
        //   getChangedJobs();
        //   router.push({ path: "/addresses/jobs/" + address.value.id + "/" + job.id });
        // }
      } catch (error) {
        if (error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function saveGig(gig, jobid) {
      // console.log(gig.id, jobid, address.value.id);
      activeTab.value.id = gig.id;
      activeTab.value.tab = "base";
      const data = {
        gig_date: gig.gig_date,
        city: gig.city,
        country_id: gig.country_id,
        status: gig.status,
        comment: gig.comment,
        updated_by: currentUser.value.id,
      };
      const jobData = {
        status: gig.status,
        updated_by: currentUser.value.id,
      };
      try {
        await store.dispatch("addLoading", 1);
        await axios.put(storeConfig.apiBaseUrl + "gig/" + gig.id, data);
        if (!willUpdateJobAndGig.value) {
          await axios.put(storeConfig.apiBaseUrl + "job/" + jobid, jobData);
        }
        toast.success(t("general.gig") + " " + t("general.successfully") + " " + t("general.edited") + ".");
        // scrollLock.value = false;
        // if (!willUpdateJobAndGig.value) {
        //   getChangedJobs();
        //   router.push({ path: "/addresses/jobs/" + address.value.id + "/" + jobid });
        // }
      } catch (error) {
        if (error.response && error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    const willUpdateJobAndGig = ref(false);
    async function updateJobAndGig(job) {
      // job.gig, job.id
      willUpdateJobAndGig.value = true;
      await save(job);
      await saveGig(job.gig, job.id);
      willUpdateJobAndGig.value = false;
      scrollLock.value = false;
      getChangedJobs();
      userHasInput.value = false;
      router.push({ path: "/addresses/jobs/" + address.value.id + "/" + job.id });
    }

    const selectedJob = ref(null);
    const delteActiveModal = ref(false);
    const toggleDeleteModal = (job) => {
      if (job) {
        selectedJob.value = job;
      }
      delteActiveModal.value = !delteActiveModal.value;
    };

    async function deleteJobGig() {
      // console.log(selectedJob.value.id, selectedJob.value.gig.id);
      if (selectedJob.value.id && selectedJob.value.gig.id) {
        try {
          await store.dispatch("addLoading", 1);
          await axios.delete(storeConfig.apiBaseUrl + "job/" + selectedJob.value.id);
          await axios.delete(storeConfig.apiBaseUrl + "gig/" + selectedJob.value.gig.id);
          toast.success(t("general.job") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
          toggleDeleteModal();
          // await getJobs();
          await getChangedJobs();
          userHasInput.value = false;
          router.push({ path: "/addresses/jobs/" + address.value.id });
        } catch (error) {
          if (error.response && error.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
        } finally {
          await store.dispatch("addLoading", -1);
        }
      }
    }

    const addModal = ref(false);
    const newGig = ref({
      gig_date: null,
      city: null,
      country_id: null,
      status: null,
      comment: null,
    });

    async function addJobNewGig() {
      addModal.value = false;
      const dataGig = {
        gig_date: newGig.value.gig_date,
        city: newGig.value.city,
        country_id: newGig.value.country_id,
        status: newGig.value.status,
        comment: newGig.value.comment,
        created_by: currentUser.value.id,
        updated_by: currentUser.value.id,
      };
      const data = {
        created_by: currentUser.value.id,
        updated_by: currentUser.value.id,
      };
      if (address.value.is_artist) {
        dataGig.artist_id = address.value.id;
        data.artist_id = address.value.id;
      }
      if (address.value.is_organizer) {
        dataGig.organizer_id = address.value.id;
        data.organizer_id = address.value.id;
      }
      try {
        await store.dispatch("addLoading", 1);
        let resultGig = await axios.post(storeConfig.apiBaseUrl + "gig", dataGig);
        if (resultGig.data.id) {
          data.gig_id = resultGig.data.id;
          newGig.value.gig_date = null;
          newGig.value.city = null;
          newGig.value.country_id = null;
          newGig.value.status = null;
          newGig.value.comment = null;
          setCityAndCountryForNewGig();
          let result = await axios.post(storeConfig.apiBaseUrl + "job", data);
          if (result.data.id) {
            activeTab.value.id = result.data.id;
            activeTab.value.tab = "base";
            toast.success(t("general.job") + " " + t("general.successfully") + " " + t("general.added") + ".");
            // await getJobs();
            await getChangedJobs();
            userHasInput.value = false;
            scrollLock.value = false;
            router.push({ path: "/addresses/jobs/" + address.value.id + "/" + result.data.id });
          }
        }
      } catch (error) {
        if (error.response && error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function updateDependencies(data, area) {
      // console.log(currentGigId.value, currentJobId.value, data, area);
      try {
        await store.dispatch("addLoading", 1);
        if (area !== "venue" && area !== "festival") {
          await axios.put(storeConfig.apiBaseUrl + "job/" + currentJobId.value, data);
        }
        if (area === "organizer" || area === "artist" || area === "venue" || area === "festival") {
          await axios.put(storeConfig.apiBaseUrl + "gig/" + currentGigId.value, data);
        }
        if (area === "organizer" || area === "artist") {
          await axios.put(storeConfig.apiBaseUrl + "job/" + currentJobId.value, data);
        }

        if (userHasInput.value) {
          // get job from jobs by currentJobId
          let job = jobs.value.find((job) => job.id === currentJobId.value);
          updateJobAndGig(job);
        } else {
          getChangedJobs();
        }

        toast.success(t("general." + area) + " " + t("general.successfully") + " " + t("general.edited") + ".");
        // await getAll();
        // await getChangedJobs();
      } catch (error) {
        if (error.response && error.response.status == 401) {
          store.dispatch("logout");
          router.push({ name: "Login" });
        }
      } finally {
        await store.dispatch("addLoading", -1);
      }
    }

    async function updateOrganizer(id) {
      searchActiveOrganizer.value = false;
      activeTab.value.id = currentJobId.value;
      activeTab.value.tab = "organizer";
      const data = {
        organizer_id: id,
        updated_by: currentUser.value.id,
      };
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateArtist(id) {
      searchActiveArtist.value = false;
      activeTab.value.id = currentJobId.value;
      activeTab.value.tab = "artist";
      const data = {
        artist_id: id,
        updated_by: currentUser.value.id,
      };
      // console.log(currentGigId.value, currentJobId.value, data, activeTab.value.tab);
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateVenue(id) {
      searchActiveVenue.value = false;
      activeTab.value.id = currentJobId.value;
      activeTab.value.tab = "venue";
      const data = {
        venue_id: id,
        updated_by: currentUser.value.id,
      };
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateFestival(id) {
      searchActiveFestival.value = false;
      activeTab.value.id = currentJobId.value;
      activeTab.value.tab = "festival";
      const data = {
        festival_id: id,
        updated_by: currentUser.value.id,
      };
      updateDependencies(data, activeTab.value.tab);
    }

    async function updateStage(id) {
      searchActiveStage.value = false;
      activeTab.value.id = currentJobId.value;
      activeTab.value.tab = "venue";
      const data = {
        stage_id: id,
        updated_by: currentUser.value.id,
      };
      updateDependencies(data, activeTab.value.tab);
    }

    const searchActiveOrganizer = ref(false);
    const searchActiveArtist = ref(false);
    const searchActiveVenue = ref(false);
    const searchActiveFestival = ref(false);
    const searchActiveStage = ref(false);

    function showModal(type, id) {
      currentJobId.value = id;
      setSelectedCategories(type);
      if (type == "Veranstalter") {
        searchActiveOrganizer.value = !searchActiveOrganizer.value;
      }
      if (type == "Künstler") {
        searchActiveArtist.value = !searchActiveArtist.value;
      }
      if (type == "Venue") {
        searchActiveVenue.value = !searchActiveVenue.value;
      }
      if (type == "Festival") {
        searchActiveFestival.value = !searchActiveFestival.value;
      }
    }

    // const tourModal = ref(false);
    // const currentTourId = ref(null);

    // async function updateTour() {
    //   tourModal.value = false;
    //   activeTab.value.id = currentJobId.value;
    //   activeTab.value.tab = "tour";
    //   const data = {
    //     tour_id: currentTourId.value,
    //     updated_by: currentUser.value.id,
    //   };
    //   updateDependencies(data, activeTab.value.tab);
    // }

    function getStatusStyles(status) {
      return typeof status !== "undefined"
        ? "background-color:#" + status.color + ";color:" + status.textcolor + ";"
        : "";
    }

    function currentStatusColors(id) {
      const status = statuses.value.find((status) => status.id == id);
      return getStatusStyles(status);
    }

    function getStatusName(id) {
      const status = statuses.value.find((status) => status.id == id);
      if (status) {
        return status.name;
      } else {
        return "";
      }
    }

    function ticketsAverage(job) {
      let ticketTypes = 0;
      let tickets = 0;
      if (job.tickets_VVK > 0) {
        ticketTypes++;
        tickets += job.tickets_VVK;
      }
      if (job.tickets_AK > 0) {
        ticketTypes++;
        tickets += job.tickets_AK;
      }
      if (job.tickets_reduced > 0) {
        ticketTypes++;
        tickets += job.tickets_reduced;
      }
      if (tickets === 0) {
        return 0;
      }
      return Math.round(((tickets + Number.EPSILON) / ticketTypes) * 100) / 100;
    }

    function seatsPotentialSeated(job) {
      if (job.capacity_seated === 0) {
        return 0;
      }
      let tickets = ticketsAverage(job);
      return (job.capacity_seated * tickets).toFixed(2);
    }

    function seatsPotentialStanding(job) {
      if (job.capacity_standing === 0) {
        return 0;
      }
      let tickets = ticketsAverage(job);
      return (job.capacity_standing * tickets).toFixed(2);
    }

    // const jobSorter = ref({
    //   date: "desc",
    //   status: "desc",
    //   organizer: "desc",
    //   artist: "desc",
    //   city: "desc",
    //   country: "desc",
    //   venue: "desc",
    //   festival: "desc",
    //   active: "date",
    // });

    function getDisplayName(address) {
      if (typeof address !== "undefined" && address !== null) {
        if (address.name !== null && address.name.length > 0) {
          return address.name;
        } else if (address.forename && address.surname) {
          return address.forename + " " + address.surname;
        } else if (address.forename) {
          return address.forename;
        } else if (address.surname) {
          return address.surname;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }

    function getCountryName(countryId) {
      const country = countries.value.find((country) => country.id == countryId);
      return country ? country.name : "";
    }

    function getCountryCode(countryId) {
      const country = countries.value.find((country) => country.id == countryId);
      return country ? country.country_code : "";
    }

    getAll();

    onUpdated(() => {
      if (route.params.jobid && document && !scrollLock.value) {
        const activeElement = document.getElementById("jobid_" + route.params.jobid);
        if (activeElement) {
          const y = activeElement.getBoundingClientRect().top + window.pageYOffset - 63; // 49;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    });

    const userHasInput = ref(false);
    function userInput() {
      // scrollLock.value = true;
      userHasInput.value = true;
    }

    const jobSorter = ref({
      active: "date",
      date: "desc",
      status: "desc",
      organizer: "desc",
      artist: "desc",
      city: "desc",
      country: "desc",
      venue: "desc",
      festival: "desc",
    });
    if (store.getters.settings.job?.list?.sort) {
      jobSorter.value = store.getters.settings.job.list.sort;
    }

    function sortJoblist(sortBy, noToggle) {
      jobSorter.value.active = sortBy;
      if (!noToggle) {
        if (jobSorter.value[sortBy] === "desc") {
          jobSorter.value[sortBy] = "asc";
        } else {
          jobSorter.value[sortBy] = "desc";
        }
      }

      if (sortBy === "date") {
        let noDateValue = "1970-01-01";
        if (jobSorter.value.date === "asc") {
          jobs.value.sort((a, b) => {
            if (!a.gig.gig_date) {
              a.gig.gig_date = noDateValue;
            }
            if (!b.gig.gig_date) {
              b.gig.gig_date = noDateValue;
            }
            return a.gig.gig_date < b.gig.gig_date ? -1 : b.gig.gig_date < a.gig.gig_date ? 1 : 0;
          });
        } else {
          jobs.value.sort((a, b) => {
            if (!a.gig.gig_date) {
              a.gig.gig_date = noDateValue;
            }
            if (!b.gig.gig_date) {
              b.gig.gig_date = noDateValue;
            }
            return a.gig.gig_date > b.gig.gig_date ? -1 : b.gig.gig_date > a.gig.gig_date ? 1 : 0;
          });
        }
      } else if (sortBy === "status") {
        if (jobSorter.value.status === "asc") {
          jobs.value.sort((a, b) => (a.gig.status < b.gig.status ? -1 : b.gig.status < a.gig.status ? 1 : 0));
        } else {
          jobs.value.sort((a, b) => (a.gig.status > b.gig.status ? -1 : b.gig.status > a.gig.status ? 1 : 0));
        }
      } else if (sortBy === "organizer") {
        if (jobSorter.value.organizer === "asc") {
          jobs.value.sort((a, b) => {
            let aName = getDisplayName(a.organizer);
            let bName = getDisplayName(b.organizer);
            return aName.toLowerCase().localeCompare(bName.toLowerCase());
          });
        } else {
          jobs.value.sort((a, b) => {
            let aName = getDisplayName(a.organizer);
            let bName = getDisplayName(b.organizer);
            return bName.toLowerCase().localeCompare(aName.toLowerCase());
          });
        }
      } else if (sortBy === "artist") {
        if (jobSorter.value.artist === "asc") {
          jobs.value.sort((a, b) => {
            let aName = getDisplayName(a.artist);
            let bName = getDisplayName(b.artist);
            return aName.toLowerCase().localeCompare(bName.toLowerCase());
          });
        } else {
          jobs.value.sort((a, b) => {
            let aName = getDisplayName(a.artist);
            let bName = getDisplayName(b.artist);
            return bName.toLowerCase().localeCompare(aName.toLowerCase());
          });
        }
      } else if (sortBy === "city") {
        if (jobSorter.value.city === "asc") {
          jobs.value.sort((a, b) => {
            let aCity = a.gig?.city ? a.gig.city : "";
            let bCity = b.gig?.city ? b.gig.city : "";
            return aCity.toLowerCase().localeCompare(bCity.toLowerCase());
          });
        } else {
          jobs.value.sort((a, b) => {
            let aCity = a.gig?.city ? a.gig.city : "";
            let bCity = b.gig?.city ? b.gig.city : "";
            return bCity.toLowerCase().localeCompare(aCity.toLowerCase());
          });
        }
      } else if (sortBy === "country") {
        if (jobSorter.value.country === "asc") {
          jobs.value.sort((a, b) => {
            let aCountry = a.gig?.country_id ? getCountryName(a.gig.country_id) : "";
            let bCountry = b.gig?.country_id ? getCountryName(b.gig.country_id) : "";
            return aCountry.toLowerCase().localeCompare(bCountry.toLowerCase());
          });
        } else {
          jobs.value.sort((a, b) => {
            let aCountry = a.gig?.country_id ? getCountryName(a.gig.country_id) : "";
            let bCountry = b.gig?.country_id ? getCountryName(b.gig.country_id) : "";
            return bCountry.toLowerCase().localeCompare(aCountry.toLowerCase());
          });
        }
      } else if (sortBy === "venue") {
        if (jobSorter.value.venue === "asc") {
          jobs.value.sort((a, b) => {
            let aName = a.gig?.venue ? getDisplayName(a.gig.venue) : "";
            let bName = b.gig?.venue ? getDisplayName(b.gig.venue) : "";
            return aName.toLowerCase().localeCompare(bName.toLowerCase());
          });
        } else {
          jobs.value.sort((a, b) => {
            let aName = a.gig?.venue ? getDisplayName(a.gig.venue) : "";
            let bName = b.gig?.venue ? getDisplayName(b.gig.venue) : "";
            return bName.toLowerCase().localeCompare(aName.toLowerCase());
          });
        }
      } else if (sortBy === "festival") {
        if (jobSorter.value.festival === "asc") {
          jobs.value.sort((a, b) => {
            let aName = a.gig?.festival ? getDisplayName(a.gig.festival) : "";
            let bName = b.gig?.festival ? getDisplayName(b.gig.festival) : "";
            return aName.toLowerCase().localeCompare(bName.toLowerCase());
          });
        } else {
          jobs.value.sort((a, b) => {
            let aName = a.gig?.festival ? getDisplayName(a.gig.festival) : "";
            let bName = b.gig?.festival ? getDisplayName(b.gig.festival) : "";
            return bName.toLowerCase().localeCompare(aName.toLowerCase());
          });
        }
      }

      if (jobSorter.value.active !== store.getters.settings?.job?.list?.sort?.active || !noToggle) {
        const settings = store.getters.settings;
        if (!settings.job) {
          settings.job = {};
        }
        if (!settings.job.list) {
          settings.job.list = {};
        }
        settings.job.list.sort = jobSorter.value;
        store.dispatch("updateSettings", settings);
      }
      // console.log("sortJobs", jobSorter.value.active, jobSorter.value[jobSorter.value.active]);
    }

    function getJobSliceClasses(job, address) {
      let ret = "";
      const toTimestamp = (strDate) => {
        const dt = Date.parse(strDate);
        return dt / 1000;
      };
      if (job.edit) {
        ret = "row tbl-brdb gws_slice gws_slice_row gws_slice_top py-2";
      } else {
        ret = "row tbl-brdb gws_slice gws_slice_row";
      }
      if (address.availabilities) {
        for (let availability of address.availabilities) {
          if (availability.from && availability.to) {
            const start = toTimestamp(availability.from);
            const end = toTimestamp(availability.to);
            const gigDate = job?.gig?.gig_date ? toTimestamp(job.gig.gig_date) : 0;
            if (gigDate >= start && gigDate <= end) {
              if (availability.type === "available") {
                ret += " gws_slice_available";
              } else if (availability.type === "unavailable") {
                ret += " gws_slice_unavailable";
              } else if (availability.type === "tourperiod") {
                ret += " gws_slice_tourperiod";
              }
              break;
            }
          }
        }
      }
      return ret;
    }

    const getAvailabilityClasses = (availability) => {
      let ret = "";
      if (availability.type === "available") {
        ret = "gws_slice_available";
      } else if (availability.type === "unavailable") {
        ret = "gws_slice_unavailable";
      } else if (availability.type === "tourperiod") {
        ret = "gws_slice_tourperiod";
      }
      return ret;
    };

    return {
      t,
      subnav,
      jobs,
      showJobsNumber,
      loadMoreData,
      address,
      countries,
      statuses,
      displayName: computed(() => {
        if (address.value.name) {
          return address.value.name;
        } else if (address.value.forename && address.value.surname) {
          return address.value.forename + " " + address.value.surname;
        } else if (address.value.forename) {
          return address.value.forename;
        } else if (address.value.surname) {
          return address.value.surname;
        } else {
          return "";
        }
      }),
      save,
      saveGig,
      updateJobAndGig,
      delteActiveModal,
      toggleDeleteModal,
      deleteJobGig,
      newGig,
      selectedCategories,
      setSelectedCategories,
      searchActiveVenue,
      searchActiveFestival,
      searchActiveOrganizer,
      searchActiveArtist,
      showModal,
      currentJobId,
      currentGigId,
      currentVenueId,
      updateOrganizer,
      updateArtist,
      updateVenue,
      updateFestival,
      addModal,
      addJobNewGig,
      // tourModal,
      // tours,
      // currentTourId,
      // updateTour,
      getStatusStyles,
      currentStatusColors,
      summNetto: function (job) {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        let netto = 0;
        // job.offer_fee + job.production_costs + job.meal_allowance + job.promomaterial + job.provision;
        if (job.offer_fee !== null) {
          netto += job.offer_fee * 1;
        }
        if (job.production_costs !== null) {
          netto += job.production_costs * 1;
        }
        if (job.meal_allowance !== null) {
          netto += job.meal_allowance * 1;
        }
        if (job.promomaterial !== null) {
          netto += job.promomaterial * 1;
        }
        if (job.provision !== null) {
          netto += job.provision * 1;
        }
        // netto = Math.round((netto + Number.EPSILON) * 100) / 100;
        netto = netto * 1;
        return netto.toLocaleString("de-DE", options);
      },
      subjecToVAT: function (job) {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        let vat = 0;
        if (job.MWSt_offer_fee && job.offer_fee !== null) {
          vat += job.offer_fee * 1;
        }
        if (job.MWSt_production_costs && job.production_costs !== null) {
          vat += job.production_costs * 1;
        }
        if (job.MWSt_meal_allowance && job.meal_allowance !== null) {
          vat += job.meal_allowance * 1;
        }
        if (job.MWSt_promomaterial && job.promomaterial !== null) {
          vat += job.promomaterial * 1;
        }
        if (job.MWSt_provision && job.provision !== null) {
          vat += job.provision * 1;
        }

        // if (job.id === 21474) {
        //   console.log(job);
        // }
        vat = vat * 1;
        // return vat.toFixed(2);
        return vat.toLocaleString("de-DE", options);
      },
      vat: function (job) {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        let vat = 0;
        if (job.MWSt_offer_fee && job.offer_fee !== null) {
          vat += job.offer_fee * 1;
        }
        if (job.MWSt_production_costs && job.production_costs !== null) {
          vat += job.production_costs * 1;
        }
        if (job.MWSt_meal_allowance && job.meal_allowance !== null) {
          vat += job.meal_allowance * 1;
        }
        if (job.MWSt_promomaterial && job.promomaterial !== null) {
          vat += job.promomaterial * 1;
        }
        if (job.MWSt_provision && job.provision !== null) {
          vat += job.provision * 1;
        }
        vat = vat * 1;
        // return (vat * (job.MWSt_rate / 100)).toFixed(2);
        return (vat * (job.MWSt_rate / 100)).toLocaleString("de-DE", options);
      },
      summBrutto: function (job) {
        const options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        let vat = 0;
        let netto = 0;
        if (job.MWSt_offer_fee && job.offer_fee !== null) {
          vat += job.offer_fee * 1;
        }
        if (job.MWSt_production_costs && job.production_costs !== null) {
          vat += job.production_costs * 1;
        }
        if (job.MWSt_meal_allowance && job.meal_allowance !== null) {
          vat += job.meal_allowance * 1;
        }
        if (job.MWSt_promomaterial && job.promomaterial !== null) {
          vat += job.promomaterial * 1;
        }
        if (job.MWSt_provision && job.provision !== null) {
          vat += job.provision * 1;
        }

        if (job.offer_fee !== null) {
          netto += job.offer_fee * 1;
        }
        if (job.production_costs !== null) {
          netto += job.production_costs * 1;
        }
        if (job.meal_allowance !== null) {
          netto += job.meal_allowance * 1;
        }
        if (job.promomaterial !== null) {
          netto += job.promomaterial * 1;
        }
        if (job.provision !== null) {
          netto += job.provision * 1;
        }
        netto = netto * 1;
        vat = vat * 1;
        vat = vat * (job.MWSt_rate / 100);
        // console.log(netto, vat);
        // return (netto + vat).toFixed(2);
        return (netto + vat).toLocaleString("de-DE", options);
      },
      localDate: function (date) {
        return new Date(date).toLocaleDateString("de-DE");
      },
      statusName: function (id) {
        const status = statuses.value.find((status) => status.id == id);
        return status.name;
      },
      getDisplayName,
      sortJoblist,

      joblistIcon: function (type) {
        if (jobSorter.value[type] === "desc") {
          return "arrow-up-circle-fill";
        } else {
          return "arrow-down-circle-fill";
        }
      },

      joblistIconColor: function (type) {
        if (jobSorter.value.active === type) {
          return " text-success";
        } else {
          return " text-secondary";
        }
      },

      // getGigFromJob: function (id) {
      // return gigs.value.find((gig) => gig.id == id);
      // },

      // checkNavitem: function (navitem) {
      //   return typeof navitem !== "undefined" && typeof navitem.title !== "undefined" ? true : false;
      // },

      getCountryName,
      getCountryCode,
      ticketsAverage,
      seatsPotentialSeated,
      seatsPotentialStanding,
      // gigs,
      // getGigs,
      getStatusName,
      userHasInput,
      userInput,
      scrollLock,
      updateStage,
      searchActiveStage,
      getJobSliceClasses,
      getAvailabilityClasses,
      nl2br,
    };
  },
};
</script>
